import React, {useState, useEffect } from 'react';
import InsightsApi from '../services/api';
import moment from 'moment';
import './main.css';
import { useParams } from 'react-router-dom';

function MainView() {
  const { secret } = useParams()

  const [displayInsights, setDisplayInsights] = useState([]);
  const [period, setPeriod] = useState('hour');

  const forPeriod = (insights) => {
    let periodInsights = [];
    let periods = 28;
    if (period === 'hour') {
      periods = 24;
    }

    for (let i = 0; i < periods; i++) {
      const from = new Date();
      const to = new Date();

      if (period === 'hour') {
        from.setHours(from.getHours() - i);
        to.setHours(to.getHours() - i - 1);
        if (i) {
          from.setMinutes(59, 59, 0);
        }
        to.setMinutes(59, 59, 0);
      } else if (period === 'day') {
        from.setHours(from.getHours() - (i * 24));
        to.setHours(to.getHours() - ((i + 1) * 24));
        if (i) {
          from.setHours(23, 59, 59, 0);
        }
        to.setHours(23, 59, 59, 0);
      } else if (period === 'week') {
        from.setHours(from.getHours() - (i * 24 * 7));
        to.setHours(to.getHours() - ((i + 1) * 24 * 7));
        if (i) {
          from.setHours(23, 59, 59, 0);
        }
        to.setHours(23, 59, 59, 0);
      }

      const ins = [];
      let total = 0;
      insights
        .filter(insight => {
          const insightTime = new Date(insight.time);
          return insightTime < from && insightTime > to;
        })
        .forEach(i => {
          let finalInsight = addPeriodImpressions(insights, i, from, to);
          if (!ins.find(a => a.id === i.id)) {
            total += finalInsight.periodImpressions;
            ins.push(finalInsight);
          }
        });

      ins.sort((a, b) => {
        const iA = new Date(a.periodImpressions);
        const iB = new Date(b.periodImpressions);
  
        if (iA < iB) {
          return 1;
        }
        return -1;
      });

      periodInsights.push({
        from,
        to,
        total,
        ins
      });
    }

    return periodInsights;
  }

  const getStartImpressionCount = (insights, insight, to) => {
    const startInsight = insights.find(i => {
      const iTime = new Date(i.time);
      return i.id === insight.id && iTime < to;
    });

    return startInsight?.impressions;
  }

  const getEndImpressionCount = (insights, insight, from) => {
    const endInsight = insights.find(i => {
      const iTime = new Date(i.time);
      return i.id === insight.id && iTime < from;
    });

    return endInsight?.impressions;
  }

  const addPeriodImpressions = (insights, insight, from ,to) => {
    const endImpressions = getEndImpressionCount(insights, insight, from);
    const startImpressions = getStartImpressionCount(insights, insight, to);
    let periodImpressions = insight.impressions;
    if (startImpressions) {
      periodImpressions = endImpressions - startImpressions;
    }

    return {
      ...insight,
      periodImpressions
    }
  }

  const loadData = async () => {
    console.log('starting data load');
    const insights = await InsightsApi.getInsights(secret);
    console.log('starting for period');
    const periodInsights = forPeriod(insights);
    console.log('starting setting display');
    const media = await InsightsApi.getMedia(secret);

    setDisplayInsights(
      periodInsights
        .map(p => {
          p.ins = p.ins.map(i => {
            const med = media.find(m => m.id === i.id);
            i.img = med?.media_url;
            return i;
          });
          return p;
        }));
  }

  useEffect(() => {
    console.log('getting started');
    loadData();
    // eslint-disable-next-line
  }, [period]);

  return (
    <div className="app">
      <h1>Impressions insights</h1>
      <h2 className="period-link" onClick={() => setPeriod('hour')}>Hourly</h2>
      <h2 className="period-link" onClick={() => setPeriod('day')}>Daily</h2>
      <h2 className="period-link" onClick={() => setPeriod('week')}>Weekly</h2>
      {
        displayInsights.map((d, index) => (
          <div className="period-container" key={`period-${index}`}>
            <div>
              <h3>
                {moment(d.from).format('D.M.YYYY HH:mm')}
              </h3>
              <p>Total: {d.total}</p>
            </div>
            {
              d.ins.map((i, index) => (
                <div className="image-block" key={`image-${index}`}>
                  <div className="image-bg" style={{ backgroundImage: `url('${i.img}')` }}>

                  </div>
                  <div className="media-info">
                    <h1>{i.periodImpressions}</h1>
                    <p>{i.impressions}</p>
                  </div>
                </div>
              ))
            }
          </div>
        ))
      }
    </div>
  );
}

export default MainView;
