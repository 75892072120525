import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MainView from "./components/main";
import Privacy from "./components/privacy";
import './index.css';

const router = createBrowserRouter([
  {
    path: "/:secret",
    element: <MainView />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
