// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #111;
  color: #fff;
  padding: 2%;
}

.period-container {
  padding: 2em;
}

.image-block {
  height: 120px;
  width: 120px;
  padding: 5px;
  position: relative;
  text-align: center;
  display: inline-block;
}

.image-bg {
  height: 100%;
  width: 100%;
  background-size: cover;
  opacity: 0.6;
}

.image-block:hover .image-bg {
  opacity: 1;
  transition: ease-out .3s;
}

.media-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.period-link {
  cursor: pointer;
  border: 1px solid white;
  display: inline;
  padding: 7px 11px;
  border-radius: 5px;
  margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/main.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,WAAW;EACX,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;AACd","sourcesContent":["body {\n  background-color: #111;\n  color: #fff;\n  padding: 2%;\n}\n\n.period-container {\n  padding: 2em;\n}\n\n.image-block {\n  height: 120px;\n  width: 120px;\n  padding: 5px;\n  position: relative;\n  text-align: center;\n  display: inline-block;\n}\n\n.image-bg {\n  height: 100%;\n  width: 100%;\n  background-size: cover;\n  opacity: 0.6;\n}\n\n.image-block:hover .image-bg {\n  opacity: 1;\n  transition: ease-out .3s;\n}\n\n.media-info {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.period-link {\n  cursor: pointer;\n  border: 1px solid white;\n  display: inline;\n  padding: 7px 11px;\n  border-radius: 5px;\n  margin: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
