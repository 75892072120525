export default class InsightsApi {
  static async getInsights(secret) {
    return await fetch(`${process.env.REACT_APP_API_URL}/${secret}/insights`)
      .then(res => res.json());
  }

  static async getMedia(secret) {
    return await fetch(`${process.env.REACT_APP_API_URL}/${secret}/media`)
      .then(res => res.json());
  }
}